@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=fallback");

@tailwind base;
@tailwind components;

// Additional styles
@import "additional-styles/utility-patterns.scss";
@import "additional-styles/range-slider.scss";
@import "additional-styles/toggle-switch.scss";
@import "additional-styles/theme.scss";
@import "~video-react/styles/scss/video-react";
@import "additional-styles/instagram-feed.scss";

@tailwind utilities;

// Additional Tailwind directives: https://tailwindcss.com/docs/functions-and-directives/#responsive
@responsive {
    .rtl {
      direction: rtl;
    }
}

// See Alpine.js: https://github.com/alpinejs/alpine#x-cloak
[x-cloak] {
  display: none;
}

// AOS styles
$aos-distance: 10px;
@import "node_modules/aos/src/sass/aos.scss";

:root {
  scrollbar-color: rgba(167, 200, 70, 1) rgb(255, 255, 255) !important;
  scrollbar-width: thin !important;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(167, 200, 70, 1);
  background: -moz-linear-gradient(
    0deg,
    rgba(167, 200, 70, 1) 0%,
    rgba(167, 200, 70, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(167, 200, 70, 1) 0%,
    rgba(167, 200, 70, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(167, 200, 70, 1) 0%,
    rgba(167, 200, 70, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a7c846",endColorstr="#a7c846",GradientType=1);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(24, 201, 180);
  background: -moz-linear-gradient(
    0deg,
    rgba(24, 201, 180, 1) 0%,
    rgba(167, 200, 70, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(24, 201, 180, 1) 0%,
    rgba(167, 200, 70, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(24, 201, 180, 1) 0%,
    rgba(167, 200, 70, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#18c9b4",endColorstr="#a7c846",GradientType=1);
}

.max-w-facebook-plugin {
  max-width: 320px;
}
